<template>
  <v-dialog
    v-model="isOpen"
    max-width="1000"
    @input="handleClose"
  >
    <v-card>
      <v-card-title class="headline pb-10">
        <div class="columns">
          <div class="column" v-if="currentServer.name === undefined">
            <h2 class="title is-3">Создать сервер</h2>
          </div>
          <div class="column" v-if="currentServer.name !== undefined">
            <h2 class="title is-3">Изменить сервер: {{ currentServer.name }}</h2>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-layout row>
          <v-layout column>
            <b-field label="Название">
              <b-input v-model="currentServer.name"></b-input>
            </b-field>
            <b-field label="IP сервера">
              <b-input v-model="currentServer.ip" :disabled="currentServer.name !== undefined"></b-input>
            </b-field>
            <b-field label="Порт сервера">
              <b-input v-model="currentServer.port" :disabled="currentServer.name !== undefined"></b-input>
            </b-field>

            <b-field label="Пароль от сервера">
              <b-input type="password"
                       v-model="currentServer.password"
                       v-validate:password="'required|min:8|max:25'"
                       password-reveal>
              </b-input>
            </b-field>
          </v-layout>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">Закрыть</v-btn>
        <v-btn text color="primary" v-if="currentServer.id == null" @click="createServer()">Добавить</v-btn>
        <v-btn text color="primary" v-if="currentServer.id != null" @click="updateServer()">Обновить</v-btn>
      </v-card-actions>
      <overlay-loader :show="isLoading"></overlay-loader>
    </v-card>
  </v-dialog>
</template>

<script>
import OverlayLoader from '@/app/shared/components/app-loader/Loader';

export default {
  name: 'ServerDetailsModal',
  components: {
    OverlayLoader,
  },
  data() {
    return {

    };
  },
  computed: {
    isLoading() {
      return this.$store.getters.IS_CURRENT_SERVER_LOADING;
    },
    isOpen() {
      return this.$store.getters.IS_SERVER_DETAILS_MODAL_OPENED;
    },
    currentServer: {
      get() {
        return this.$store.getters.GET_CURRENT_SERVER;
      },
      set(value) {
        this.$store.commit("SET_CURRENT_SERVER", value);
      },
    },
  },
  methods: {
    close() {
      this.$store.dispatch("CURRENT_SERVER", { });
      this.$store.dispatch('SERVER_DETAILS_MODAL', false);
    },
    createServer(){
      this.$store.dispatch('CREATE_SERVER', this.currentServer)
        .then(() => {
          this.$toast.success("Сервер успешно добавлен");
          this.$store.dispatch("CURRENT_SERVER", { });
          this.$store.dispatch("GET_SERVERS");
          this.$store.dispatch('SERVER_DETAILS_MODAL', false);
        })
        .catch(() => {
          this.$toast.error("Ошибка при добавлении сервера");
        });
    },
    updateServer(){
      this.$store.dispatch('UPDATE_CURRENT_SERVER', this.currentServer)
        .then(() => {
          this.$toast.success("Сервер успешно изменен");
          this.$store.dispatch("CURRENT_SERVER", { });
          this.$store.dispatch("GET_SERVERS");
          this.$store.dispatch('SERVER_DETAILS_MODAL', false);
        })
        .catch(() => {
          this.$toast.error("Ошибка при изменении сервера");
        });
    },
    handleClose(isOpen) {
      if (!isOpen) {
        this.close();
      }
    }
  },
};
</script>
